import React from 'react';

function Result(props) {
    let questions = props.questions;
    questions.forEach(q => { q.isCorrect = q.options.every(x => x.selected === x.isAnswer); })

    return (
        <div className="result">
            <h2 className="text-center font-weight-normal">Vyhodnotenie testu</h2>
            {questions.map((q, index) =>
                <div key={q.id} className={`mb-2 ${q.isCorrect ? 'bg-success' : 'bg-danger'}`}>
                    <div className="result-question">
                        <h5>{index + 1}. {q.name}</h5>
                        <h5 style={{margin:"20px 0", display:q.snippet?"block":"none"}}>
                            <pre>{q.snippet}</pre>
                        </h5>
                        <center style={{margin:"20px 0", display:q.img?"block":"none"}}>
                            <img src={q.img} alt=""/>
                        </center>
                        <div className="row">
                            {
                                q.options.map(option =>
                                    <div key={option.id} className="col-6">
                                        <input id={option.id} type="checkbox" disabled="disabled" checked={option.selected} />                                         
                                        <span>{option.name}</span>
                                        <img src={option.img} alt="" style={{display:option.img?"inline":"none", marginLeft:option.name?"10px":"0"}}/>
                                        <div style={{margin:"20px 0", display:option.snippet?"block":"none"}}>
                                            <pre>{option.snippet}</pre>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className={`m-1 p-1 text-bold ${q.isCorrect ? 'text-success' : 'text-danger'}`}>Odpoveď je {q.isCorrect ? 'správna' : 'nesprávna'}.</div>
                    </div>
                </div>
            )}
            <h4 className="alert alert-info text-center">Teraz môžete toto okno zavrieť.</h4>
        </div>
    )
}

export default Result;